.expense-date {
  display: flex;
  flex-direction: column;
  width: 5.5rem;
  height: 5.5rem;
  border: 1px solid #ececec;
  background-color: #2a2a2a;
  color: white;
  border-radius: 12px;
  align-items: center;
  justify-content: center;
}

.expense-date__month {
  font-size: 0.75rem;
  font-weight: bold;
}

.expense-date__year {
  font-size: 0.75rem;
}

.expense-date__day {
  font-size: 1.5rem;
  font-weight: bold;
}
 @media screen and (max-width: 480px) {
    .expense-item__day {
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      flex: 1;
    }
  
    .expense-item__month {
      font-size: 1.25rem;
    }
  
    .expense-item__year {
      font-size: 1.25rem;
      padding: 0.5rem 1.5rem;
    }
  }  

  /* @media screen and (max-width: 480px) {
    .expense-date {
      padding: 0.5rem 0.5rem;
    }
  } */
